import { configureStore }       from '@reduxjs/toolkit'

import rootReducer              from './RootReducer'
import { bomApi }               from 'src/services/rtk_api/bom/BomApi';
import { sessionApi }           from 'src/services/rtk_api/user/sessionApi';
import { userApi }              from 'src/services/rtk_api/user/UserApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat([
        bomApi.middleware,
        sessionApi.middleware,
        userApi.middleware,
      ])
})

export default store