import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getUserToken } from 'src/shared/utils/session'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: (headers, { getState }) => {
      const token = getUserToken()
      if (token) {
        headers.set('X-User-Token', token)
      }
      return headers
    }
  }),
  tagTypes: ['User', 'UserBom'],
  endpoints: (builder) => ({
    getUserBoms: builder.query({
      query: ({ q, page }) => `boms/user_boms?${q}&page=${page}&per_page=10`,
    }),
    getUserOrders: builder.query({
      query: ({ q, page }) => `orders/user_orders?${q}&page=${page}&per_page=10`,
    }),
    getCurrentUser: builder.query({
      query: () => 'current_user',
    }),
    // getCurrentUserConfigurations: builder.query({
    //   query: () => 'current_user/configurations',
    // }),
    getCurrentUserPreferences: builder.query({
      query: () => 'current_user/preferences',
      providesTags: ['UserPreferences'],
    }),
    updateCurrentUserConfiguration: builder.mutation({
      query: (body) => {
        return {
          url: 'current_user/configurations',
          method: 'PUT',
          body: body,
        }
      },
    }),
    updateUserWithForm: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `users/${id}`,
          method: 'PUT',
          body: formData
        }
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.id }],
    }),
    updateCurrentUserPreferences: builder.mutation({
      query: (body) => {
        return {
          url: 'current_user/preferences',
          method: 'PUT',
          body: body,
        }
      },
      invalidatesTags: (_result, _error, _arg) => [{ type: 'UserPreferences' }],
    }),
  }),
})

export const {
  // useGetUsersQuery,
  useLazyGetUserBomsQuery,
  // useLazyGetUserOrdersQuery,
  // useLazyGetUserQuery,
  // useGetUserQuery,
  // useUpdateUserMutation,
  useUpdateUserWithFormMutation,
  // useCreateUserMutation,
  // useLazyGetCurrentUserConfigurationsQuery,
  useGetCurrentUserPreferencesQuery,
  // useLazyGetCurrentUserPreferencesQuery,
  // useGetCurrentUserQuery,
  // useLazyGetCurrentUserQuery,
  // useUpdateCurrentUserConfigurationMutation,
  useUpdateCurrentUserPreferencesMutation,
} = userApi