import {combineReducers}        from '@reduxjs/toolkit'
import { bomApi }               from 'src/services/rtk_api/bom/BomApi';
import { sessionApi }           from 'src/services/rtk_api/user/sessionApi';
import { userApi }              from 'src/services/rtk_api/user/UserApi';

const rootReducer = combineReducers({
  [bomApi.reducerPath]: bomApi.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

export default rootReducer;