import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getUserToken } from 'src/shared/utils/session'
import queryString from 'query-string'

export const bomApi = createApi({
  reducerPath: 'bomApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/boms',
    prepareHeaders: (headers) => {
      const token = getUserToken()
      if (token) {
        headers.set('X-User-Token', token)
      }
      return headers
    }
  }),
  tagTypes: ['Boms', 'Bom', 'Version'],
  endpoints: (builder) => ({
    getBoms: builder.query({
      query: ({ q, page }) => {
        const defaultQuery = {
          'q[owner_id_null]': true,
          'q[name_null]': false,
          'q[s]': 'updated_at desc',
        }

        q = queryString.stringify({ ...defaultQuery, ...q })

        return `?${q}&page=${page}`
      },
      providesTags: (result) => result ? result.boms.map(({ id }) => ({ type: 'Boms', id })) : ['Boms']
    }),
    getBom: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => error ? [{ type: 'Bom', id }] : [{ type: 'Bom', id }, 'Boms']
    }),
    getVersions: builder.query({
      query: (id) => `/${id}/versions`,
      providesTags: ['Version']
    }),
    createNewBom: builder.mutation({
      query: ({ formData }) => ({
        url: '/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['Bom']
    }),
  }),
})

export const {
  useLazyGetVersionsQuery,
  useLazyGetBomsQuery,
  useLazyGetBomQuery,
  useCreateNewBomMutation,
} = bomApi
