import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, useNavigate } from 'react-router-dom'

import routes from './routes';
import store from 'src/front_site/Store'

import 'stylesheets/front-site.scss';

import {NextUIProvider} from '@nextui-org/react';

const App = () => {
  const navigate = useNavigate();
  return (
    <NextUIProvider navigate={navigate}>
      {routes}
    </NextUIProvider>
  )
}

const FrontSite = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
}

export default FrontSite;
