import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import Login from './components/login/login';

import {
  isUserLoggedIn,
  setUserToken,
} from 'src/shared/utils/session'

import {
  useCreateNewSessionMutation
} from 'src/services/rtk_api/user/sessionApi';


const SessionPage = (props) => {
  const [messages, setMessages] = useState([]);
  const [createNewSession, {
    data: loggedUserResponse,
    isLoading: loginLoading,
    error: loginError,
    isSuccess: loginSuccess
  }] = useCreateNewSessionMutation();

  if (isUserLoggedIn()) return <Navigate to="/" />

  const onLogin = (email, passwd) => {
    // this could by try catched, and handle success and error separately
    createNewSession({ formData: { email: email, password: passwd } })
  }

  useEffect(() => {
    if (loginError) toast.error(loginError.data.message)
    if (loginSuccess) {
      const { authentication_token } = loggedUserResponse.data
      setUserToken(authentication_token)
      window.location.href = '/'
    }
  }, [loginSuccess, loginError])

  return (
    <div id="sessions">
      <div className="container my-8 md:my-0">
        <div className="md:h-[calc(100vh-200px)] flex flex-col align-content-center">
          <div className="w-10/12 md:w-5/12 m-auto">
            <Login
              loading={loginLoading}
              onLogin={onLogin} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionPage;